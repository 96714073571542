import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from '../../components/seo'
import { Link} from 'gatsby'
        
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>

          <SEO title="Hafið samband við okkur"
                description="" 
                pathname="/contact/"
            />
         <Hero 
         headerText="Hafa samband" 
         subHeaderText="Séuð þið með spurningar um hvernig þjónustan virkar eða viljið koma fyrirtæki ykkar á framfæri"
         heroBanner="https://images.squarespace-cdn.com/content/v1/52a74d9ae4b0253945d2aee9/1390513380961-PTHFXE5U2S1FJSLPXUSD/ke17ZwdGBToddI8pDm48kA2MStDQoZ8TsVhOdZz9ancUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc02ZN02e9uXhS_VCIvN0BPHpXNLjw_J6OzsHaJFwWx58AMuMC3AT9ww3KmldDNe7v/tumblr_mh1iruZWLf1rkz363o1_1280.jpg?format=1500w" /> 

        <section className="section column is-10 is-offset-1 content">
          
             
              <p className="container">Vinsamlegast hafið samband með tölvupósti <Link to="mailto:info@getlocl.com">info@getlocl.com</Link> eða með því að hringja í 888 0020.</p>
              
              <div className="columns is-10 is-offset-1 margin-above">
                <div className="column is-6">
                  <h2>Getlocal Iceland</h2>
                  <p>Garðatorg 5,<br />
                  201, Garðabær,<br />
                  <a href="mailto:info@getlocl.com">info@getlocl.com</a> <br />
                  +354 888 0020</p>
                </div>
               
              </div>
       
        </section>
      </Layout>
    )
  }
}